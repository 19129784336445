// Common variables
$primary: #0f1728;
$secondary: #bf2146;

// Media query variables
$mq-max-width: 768px;

// Variables with media query adjustments
$white: #f4f4f4;
$black: mix($primary, black, 10%);
$gray: mix($primary, lightgray, 10%);

$body-font: 'Lora', serif;
$display-font: 'Quicksand', sans-serif;