@import "./variables.scss";

.app {
  // display: flex;
}

main,
p {
  // font-family: $body-font;

}

main {
  min-height: 100vh;
}

.body {
  max-width: 960px;
  margin: 0 auto;

  @media (max-width: $mq-max-width) {
    max-width: 90vw;
  }
}

h1,
h2,
h3,
p {
  font-family: $display-font;
  font-weight: 400;
}

h2,
h3,
p {
  color: $primary;
}

h1 {
  color: $white;
  margin: 0;
  font-size: 2em;

  @media (max-width: $mq-max-width) {
    font-size: 1.3em;
  }
}

h2 {
  text-align: center;
}

*:focus {
  outline: max(2px, 0.15em) solid $secondary;
  outline-offset: max(2px, 0.15em);
}

body {
  // background-color: $white;
  background-image: url('./assets/images/so-white.png');
  background-size: 300px;

}



.no-results,
#content {
  color: $black;
  margin: 0;
  text-align: center;
  width: 100%;
  height: 100%;
}



.no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-results-logo {
  height: 150px;
}

.circle-radio {
  margin: 0em .25em;
  color: $black;

  input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    margin-right: .2em;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.1em solid $secondary;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $secondary;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
}



header {
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;

  .header-inner {
    display: flex;
  }

  .title-container {
    display: flex;
    align-items: center;
  
    .logo {
      height: 3em;
      width: 3em;
      background-image: url('./assets/images/logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 1em;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
  
    p {
      color: $white;
      margin: 0;
  
      @media (max-width: $mq-max-width) {
        display: none;
      }
    }
  
  }

  button {
    margin: .5em;
    padding: .5em 1em;
    background-color: transparent;
    color: $white;
    border: none;
    border-radius: 4px;



    &:disabled {
      color: $gray;
    }

    &:hover:not(:disabled) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.modal-grid {
  display: flex;
}

.rating-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.edit-modal-actions,
.submit-modal-actions,
.nested-modal-actions {
  display: flex;
  justify-content: flex-end;
}

#top-button-container {
  display: none;
}

.submit-button-container, .top-button-container {
  position: fixed;
  padding: 1em;
  bottom: 2em;
  right: 2em;

  .submit-button, .top-button {
    height: 4em;
    width: 4em;
    color: white;
    background-color: rgba($secondary, 1);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: all .5s;
    cursor: pointer;

    &:hover {
      background-color: mix($secondary, black, 85%);
    }
  }
}

.error-message {
  color: red;
  margin: 5px 0 0 0;
}

.button {

  color: $white !important;
  border: none;
  padding: .8em 1.2em;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;

  &:first-child:not(:last-child) {
    margin-right: 1em;
  }

  &.primary {
    background-color: $secondary;

    &:hover {
      background-color: mix($secondary, $black, 85%);
    }
  }

  // I know
  &.secondary {
    background-color: transparent;
    color: $primary !important;

    &:hover {
      background-color: rgba($primary, .1);
    }

    
  }


}

.color-picker {
  padding: 1em 0;
  border-radius: 4px;

  .circle-picker {
    margin-top: 1em;
  }
}

.search-rank-container {
  width: 100%;
  display: flex;
  padding: 1em 0;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: $mq-max-width) {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  .search-container {
    position: relative;
    font-family: $body-font;

    #search {
      background-color: mix($white, #b6a4ae, 70%);
      padding: 1em 2.4em;
      border: none;
      border-radius: 4px;
      width: 300px;
      margin-right: 1em;

      @media (max-width: $mq-max-width) {
        margin-right: 0em;
        padding: 1em 2.8em;
      }
    }

    .search-icon {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
    }
  }

  .rank-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1em;

    @media (max-width: $mq-max-width) {
      margin: .5em;
    }

    .star-radios {
      display: flex;
      justify-content: center;
    }

    .circle-radios {
      display: flex;
    }
  }
}

input[type="text"] {
  width: 40vw;
  border: none !important;
  background-color: white;

  &::placeholder {
    color: $gray;
  }

  @media (max-width: $mq-max-width) {
    width: 80vw;
  }
}

.search-icon {
  position: absolute;
  right: 10px;
}

.circle-radio {
  display: flex;
  align-items: center;

  input[type="radio"] {
    width: 1em;
    height: 1em;

    @media (max-width: $mq-max-width) {
      width: 1em;
      height: 1em;
    }
  }
}

.star-radio {
  display: none;
}

.star-label {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  margin: .1em;
  background-image: url('./assets/images/emptyStar3D.webp');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.star-label.selected,
.hovered {
  background-image: url('./assets/images/fullStar.webp');
}

.selected {
  &.unhovered {
    opacity: .7;
  }
}


footer{
  background-color: rgba($primary, 1);
  padding: 1em;

  h3, p{
    color: $white;
    margin: .25em 0;
  }

  .title-container {
    display: flex;
    align-items: center;
  
    .logo {
      height: 2em;
      width: 2em;
      background-image: url('./assets/images/logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 1em;
    }
  }

} 
