@import "./../../variables.scss";

.card {
    width: 100%;
    padding: 5px;
    color: $black;
    border-top: 1px solid $gray;
    padding: 3em 0em;

    &:last-child {
        border-bottom: 1px solid $gray;
    }

    .inner-card {
        padding: 0 5rem;

        @media (max-width: $mq-max-width) {
            padding: 0;
        }
    }

    .name-date-wrapper {
        display: flex;
        justify-content: space-around;

        .brand {
        flex: 1;
        }
    
        .date-added{
            width: fit-content;
            text-align: right;
            color: $gray;
        }
    }



    .card-content {
        display: flex;
        align-items: flex-start;

        @media (max-width: $mq-max-width) {
            flex-direction: column;
        }
    }

    .uploaded-img {
        height: 200px;
        width: 200px;
        background-color: white;
        margin-right: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: 3px solid $primary;
        margin-bottom: 0;
        filter: drop-shadow(8px 7px 0px $primary);

        @media (max-width: $mq-max-width) {
            width: 100%;
            filter: none;
            margin-right: 0;
            margin-bottom: 1em;
        }
    }

    h2, h3, p {
        text-align: left;
        margin: 0;
    }

    h2 { font-size: 2.1em; }
    h3 { font-size: 1.2em; }

    h2, h3, .color { text-transform: capitalize; }

    .chips { display: flex; }

    .footer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    .inactive {
        opacity: .8;
        color: $gray;
    }

    .notes {
        font-family: $body-font;
        margin: 1em 0;
    }

    .chip-span, .color-span {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: .5em .25em .5em 0em;

        .chip {
            color: $white;
            padding: .15em .75em;
            border-radius: 25px;
            background-color: red;
            width: fit-content;
            font-size: .8em;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;

        }

        .price {
            background-color: $primary;
            margin: 0;
            width: 100%;
            text-align: right;
        }
    }

    .color-circle {
        height: 1em;
        width: 1em;
        background-color: $secondary;
        border-radius: 100%;
        margin-right: .3em;
    }

    .details {
        flex: 1;
        width: 100%;
    }

    .color { color: $gray; }

    .img {
        height: 5em;
        width: 5em;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: 1em;

        &.good { background-image: url("../../assets/images/good.webp"); }
        &.bad { background-image: url("../../assets/images/bad.webp"); }
        &.closed { background-image: url("../../assets/images/closed.webp"); }
        &.question { background-image: url("../../assets/images/question.webp"); }
    }
}

.card:not(.inactive) {
    // box-shadow: 10px 6px 24px -19px rgba(0, 0, 0, 0.75);
}

.prev-img {
    height: 3em;
    margin-right: 1em;
}

.edit-file-input {
    display: flex !important;
    align-items: center;
}
